$mainYellow: #fff300;
$transition: 0.3s all ease-in-out;
$margin: 40px;
$shitYellow: #fed953;
$lightBlack: #333333;
$lightGrey: #999999;

#login_on1 {
  position: relative;
  display: none;
  width: 100px;
  height: 40px;
  margin-top: 10px;

  #show_personal {
    width: 100px;
    height: 40px;
    line-height: 40px;
    padding-left: 5px;
    padding-right: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    img {
      border-radius: 50%; // height: 93%;
      width: 37px;
      height: 37px;
      background-color: white;
    }
  }

  &>div {
    display: none;
  }

  a {
    padding-left: 0;
    padding-right: 0;
  }
}

.personal_center_active {
  // color: #FFF300;
  background: #2c2624;
  box-shadow: 1px 1px 1px #25201e inset, -1px -1px 1px #25201e inset;
  transition: 0.3s all ease-in-out;
}

.personal_center {
  position: absolute;
  margin-left: 0;
  top: 100%;
  right: 0;
  height: 0;
  overflow: hidden;
  z-index: 1;

  a {
    color: white;

    &:hover {
      color: white;
    }
  }

  div {
    width: 100px;
    height: 40px;
    padding-left: 5px;
    line-height: 40px;
    background: #27292e;
    color: #fff;

    &:hover {
      color: $shitYellow;
      transition: 0.3s all ease-in-out;
    }
  }
}

#login_sign_up:hover,
#login_sign_in:hover {
  color: #ffd53f;
}

#nav {
  height: 70px;
  text-align: center;

  ul {
    position: relative;
    /* move 100px to left so it looks like in the middle... */
    margin-left: -100px;
  }

  span {
    display: inline-block;
  }

  img {
    height: 70px;
    padding: 15px;
    padding-left: 0;
    float: left;
    /*display: none;*/
    /*    margin-left: -70px;*/
    transform: scale(0, 0);
  }
}

#nav .navdropdown>ul {
  display: none;
  margin-left: 0;
  z-index: 3;
  background: #2c2624;

  &>li {
    line-height: 2rem;
    padding: 5px;
    padding-left: 12px;

    &:hover {
      box-shadow: 1px 1px 1px #25201e inset, -1px -1px 1px #25201e inset;
    }

    &>a {
      color: #fff;

      &:hover {
        color: #fff300;
      }
    }
  }
}

#search {
  position: absolute;
  width: 0;
  height: 70px;
  padding-top: 18px;
  left: 100%;
  display: none;
  color: #4a4a4a;
  text-overflow: ellipsis;
  white-space: nowrap;

  i {
    position: absolute;
    left: 1rem;
    top: 2.4rem;
    padding-right: 10px;
  }

  input {
    width: 100%;
    line-height: 2.7rem;
    background: #eee;
    padding-left: 5px;
    border: 0;
  }

  input:focus {
    outline: none;
  }
}

i#search_prompt {
  position: absolute;
  left: 2.5rem;
  top: 2.1rem;
  padding-right: 10px;
  font-style: normal;
}

/* cover the original style, make the navbar height 70px */

/*
    the sytle when navbar's width less than 767px
 */

@media (max-width: 767px) {
  #logo {
    display: none;
  }

  .blank {
    display: none !important;
  }

  .collapse.navbar-collapse.top-nav {
    display: none !important;
  }

  #pc-header-con {
    display: none;
  }

  .navbar-fixed-top {
    height: 50px;
  }

  #navbar {
    display: block; //        position: fixed;
    //        right: 0;
    //        left: 0;
    //        top: 0;
    z-index: 1030;

    .navbar {
      background: #27292e;
      margin-bottom: 0;
      border: 0;
    }

    .navbar-toggle {
      background: #333;
    }

    .navbar-toggle:hover {
      background: #ccc;
    }

    .icon-bar {
      background: #fff;
    }
  }

  #mobilenavbar {
    display: block;
    text-align: center;
  }

  #slidenav_exit {
    display: none;
  }

  #slidenav_search {
    position: relative;
    display: none;
    width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 100%;
    height: 50px;
    border-radius: 3px;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 12px;

    &>div {
      float: left;
    }

    input {
      line-height: 2.6rem;
      height: 2.6rem;
      padding-left: 10px;
      border-radius: 3px;
      width: 100%;
      border: 0;

      &:focus {
        outline: none;
      }
    }

    i {
      position: absolute;
      left: 30px;
      top: 18px;
      padding-right: 10px;
    }

    .cancel {
      position: absolute;
      height: 22px;
      top: 15px;
      right: 20%;
      display: none;
    }
  }

  img#mobile_navbar_logo {
    height: 50px;
    margin-left: -32px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  #cancel_search {
    min-width: 3.2rem;
    line-height: 32px;
    padding-left: 3.5%;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }

  #slidenav_search_con {
    &:hover {
      cursor: pointer;
    }
  }

  #slidenav_input_con {
    width: 85%;
  }

  #slidenav_search_con {
    float: right;
    color: #fff;
    line-height: 50px;
    padding-right: 15px;
    display: block;
  }

  #btn_slidenavbar {
    display: block;
    background: #323232;

    &:focus {
      background: #323232;
    }
  }

  .slidenav_clearfix {
    clear: both;
  }

  i#search_prompt {
    top: 15px;
    left: 48px;
  }

  #nav img,
  #report {
    display: none;
  }

  .reply_area img {
    display: none;
  }

  .art_aside {
    // margin-top: 30px;
  }
}

/* login pop div */

#login_bg {
  width: 100%;
  height: 100%;
  z-index: 2000;
  position: fixed;
  display: none;
  top: 0;

  input {
    color: #fff;
    opacity: 0.8;
    letter-spacing: 1px;
  }
}

#sign_up_bg,
#sign_in_bg {
  height: 510px;
  width: 350px;
  position: absolute;
  top: 70px;
  border-radius: 3px;
  background: #323232;
  box-shadow: 0px 0px 5px #272727;
  border: 1px solid #272727;
}

#sign_up_bg {
  z-index: 2002;
  right: 50%;
}

#sign_in_bg {
  z-index: 2002;
  left: 50%;
}

.sign_container {
  padding: 0 65px 30px;

  &>div {
    text-align: center;
  }

  img {
    height: 40px;
  }

  div {
    padding: 5px;
    font-size: 1.4rem;
    overflow: hidden;
  }

  a {
    color: #5c5c5c;
  }

  h5 {
    font-size: 1.4rem;
    color: #9a9a9a;
    padding: 15px;
  }

  input {
    width: 100%;
    height: 30px;
    line-height: 3rem;
    padding-left: 6px;
    background: #1f1f1f;
    border: 1px solid #2c2c2c;
    border-radius: 3px;

    &:focus {
      outline: none;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: relative;
    float: left;
    line-height: 1.2rem;
    width: 12px;
    height: 12px;
    margin-right: 2px; // &::before{
    //     position: absolute;
    //     display: block;
    //     content: '';
    //     top: 0;
    //     left: 0;
    //     height: 12px;
    //     width: 12px;
    //     z-index: -1;
    // }
    // &:checked{
    //     z-index: 2;
    // }
    // &:checked::before{
    //     background: #fff000;
    //     opacity: 0.5;
    //     z-index: 1;
    // }
  }

  label {
    font-weight: normal;
    float: left;
    margin: 0;
    font-size: 1.2rem;
  }
}

.sign_logo {
  padding: 5px;
  text-align: center;
  margin-top: 30px;

  img {
    height: 40px;
  }
}

.sign_in_con {
  opacity: 0;
}

.sign_up_con {
  opacity: 0;
}

//#phone_number,
//#forget_phone_number {
//    float: left;
//    width: 128px;
//}
.sign_btn {
  height: 30px;
  background: #fff300;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 3px;
  border: 0;
  width: 100%;
  color: #323232;
  font-weight: 600;

  &:focus {
    outline: 0 auto -webkit-focus-ring-color;
    background-color: #1f1f1f;
  }
}

//#get_cert_btn,
//#forget_get_cert_btn {
//    float: right;
//    width: 72px;
//    font-size: 1.2rem;
//}
.littlefontsize {
  font-size: 1.2rem;
}

#third_sign {
  display: none;
  border-top: 1px solid #393939;
  margin-top: 15px;
  text-align: center;

  div {
    display: inline-block;
  }

  span {
    font-size: 4rem;
  }
}

#third_sign_qq:hover {
  color: #00acc2;

  span {
    color: #00acc2;
  }
}

#third_sign_weichat:hover {
  color: #30b100;

  span {
    color: #30b100;
  }
}

#third_sign_weibo:hover {
  color: #fe5d5d;

  span {
    color: #fe5d5d;
  }
}

/* 手机端导航栏css */

#slidenavbar_cover,
#slidenavbar_cover2 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 18;
  display: none;
  background: #000;
  opacity: 0.3;
}

.menu-wrap-navbar {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.menu-wrap {
  //    position: fixed;
  position: absolute; //    height: 1000px;
  z-index: 1001;
  height: 100vh;
  -webkit-transform: translate3d(-100px, 0, 0);
  transform: translate3d(-100px, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.content-wrap {
  // overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

/* Shown menu */

.show-menu .menu-wrap-navbar {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.show-menu .menu-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.show-menu .content-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.show-menu .slidenavbar_container {
  width: 100%;
}

.slidenavbar_container {
  background: #323232;
  height: 100%;
  width: 80%;
}

.slidenavbar_main_con {
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
}

.slidenavbar_person_head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  height: 42px;
  line-height: 42px;
}

.slidenavbar_logo {
  height: 50px;
  padding: 15px;
  width: 100%;
  text-align: center;
  background: #3f3b3a;

  &>img {
    height: 100%;
  }
}

a#to_admin_page2 {
  display: none;
}

a#to_admin_page1 {
  display: none;
}

.slidenavbar_personimg {
  width: 80px;
  margin: 0 auto;

  &>img {
    width: 100%;
    border-radius: 50%;
  }
}

#slidenavbar_login {
  font-size: 14px;

  a {
    color: #fff;
  }
}

.slidenavbar_nav {
  &>ul>li {
    line-height: 42px;
    height: 42px;

    &>a {
      color: #fff;
      font-size: 14px;
    }
  }
}

#slidenavbar_login {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#btn_slidenavbar {
  float: left;
  margin-left: 5px;
}

#slidenavbar_uc {
  display: none;
}

#slidenavbar {
  display: none;
}

// new header start
header {
  background: #27292e;
  transition: $transition;
  font-size: 15px;

  &.navbar-fixed-top {
    position: fixed;
    background: #27292e;
    box-shadow: 0px 1px 2px 0px rgba(68, 68, 68, 0.2);
  }

  &:hover {
    transition: $transition;
  }

  .cate-report {
    height: 60px;
    font-size: 15px;

    .nav_logo {
      margin-top: 18px;
      margin-right: 50px;
      width: 84px;
      height: 24px;

      a {
        width: 100%;
        height: 100%;
        display: inline-block;
        background: url("../imgs/nav_logo.png") no-repeat center;
        background-size: 100%;
      }
    }

    .cates {
      font-size: 0;

      li {
        display: inline-block;
        padding-right: 40px;
        padding-left: 0px;
        font-size: 15px;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        a {
          position: relative;
          display: inline-block;
          margin-top: 18px;
          padding-bottom: 7px;
          color: #FFFFFF;
        }

        &.cate-active {
          font-weight: bold;

          a::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translateX(-50%);
            width: 60%;
            height: 2px;
            border-radius: 4px;
            background-color: #FFFFFF;
          }
        }

        .vip-span {
          display: inline-block;
          position: relative;
          color: #fff;
          padding: 15px 15px 10px 0;

          .vip-right {
            position: absolute;
            right: 0px;
            top: 23px;
            transform: scale(0.8);
          }

          .hover-box {
            display: none;
            position: absolute;
            left: 50%;
            top: 45px;
            padding: 20px 10px;
            transform: translateX(-50%);
            font-size: 0;
            background: #ffffff;
            box-shadow: 0 4px 20px 0 rgba(108, 113, 121, 0.43);

            .up-triangle {
              position: absolute;
              left: 50%;
              top: -8px;
              transform: translateX(-50%);
              width: 0;
              height: 0;
              display: inline-block;
              border-bottom: 8px solid #ffffff;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
            }

            a {
              display: inline-block;
              width: 140px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              padding: 0;
              margin: 0;
              font-size: 15px;
              color: #27292e;

              &:hover {
                background-color: #FCDB00;
              }
            }
          }

          &:hover {
            font-weight: bold;

            .hover-box {
              display: block;
            }
          }
        }
      }
    }
  }

  .search-log {
    position: relative;

    .app-icon-download {
      position: relative;
      margin-top: 20px;
      margin-right: 25px;
      color: #fff;
      cursor: pointer;

      &:hover {
        color: #FCDB00;

        .app_qr_float {
          display: block;
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .app_qr_float {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: 10px;
        margin-left: -129px;
        width: 140px;
        height: 140px; // background-color: white;
        // box-shadow: 0 2px 4px 0 #aaa;
        padding: 5px;
        background: url("../imgs/app_qr_float.png") no-repeat;
        background-size: 100%;

        &::before {
          content: '';
          border-bottom: 10px white solid;
          border-right: 5px transparent solid;
          border-left: 5px transparent solid;
          position: absolute;
          left: 50%;
          margin-left: -5px;
          top: -7px;
        }
      }
    }

    .header-notify {
      position: relative;
      margin-top: 21px;
      margin-right: 20px;

      .header-notify-icon {
        display: block;
        color: #fff;

        &:hover {
          color: #FCDB00;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }

        .header-notify-badge {
          display: none;
          position: absolute;
          top: 4px;
          left: 11px;
          width: 6px;
          height: 6px;
          border-radius: 100px;
          background-color: red;
        }
      }
    }

    .keyword-wra {
      position: relative;
      margin-top: 20px;
      margin-right: 25px;
      color: #fff;
      cursor: pointer;

      &:hover {
        color: #FCDB00;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .submit_project {
      position: relative;
      margin-top: 16px;
      margin-right: 25px;
      color: #fff;
      cursor: pointer;
      width: 80px;
      height: 28px;
      border-radius: 14px;
      border: 1px solid rgba(252, 219, 0, 1);
      font-size: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        color: rgba(252, 219, 0, 1);
      }
    }

    .login_sigin_con {
      margin-top: 22px;
      color: #fff;
      cursor: pointer;
      display: none;

      &:hover {
        color: #FCDB00;
      }
    }
  }
}

#login_on1 {
  float: right;
  margin-left: -20px;
  display: none;
}

header a:hover,
header a:link,
header a:focus,
header a:visited {
  transition: 0s all ease-in-out;
}

//  search modal
#headerSearchModal {
  display: none;
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  background-color: #fff;

  .header-search-input {
    position: relative;
    margin-top: 200px;
    text-align: center;

    &-elm {
      width: 60%;
      padding: 15px 0;
      border: 0;
      border-bottom: 1px solid #DFDFE0;
      outline: none;
      border-radius: 0px;

      &::after {
        border: 0;
      }
    }

    &-close {
      position: absolute;
      right: 10%;
      top: -80px;
      font-size: 20px;
      cursor: pointer;
    }

    &-search {
      position: absolute;
      right: 21%;
      top: 22px;
      font-size: 18px;
      color: #27292E;
      cursor: pointer;
    }
  }
}

#navbar {
  display: none;
}

.mobile_filter_container {
  display: none;
}

@media (max-width: 1199px) {
  header {
    .cate-report {
      .cates {
        li {
          padding-right: 15px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  header {
    .search-log {
      font-size: 1.4rem;
    }

    #login_on1 {
      margin-right: 10px;
    }

    .cate-report {
      font-size: 1.4rem;

      .nav_logo {
        display: none;
        width: 50px;
        height: 16px;
        margin-top: 20px;
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  #navbar {
    display: block;
    border-radius: 0;

    #mobilenavbar {
      background: #323232;
      display: block;
    }
  }

  #slidenavbar {
    display: block;
    width: 100px;
    z-index: 1032;
    margin-top: 50px;
  }

  //mobile filter
  .mobilenav_active {
    color: #333333;
  }

  #mobile_filter_menu_con {
    min-height: 40px;
    background: #fff;
    position: absolute;
    z-index: 17;
    text-align: center; //        border-top: 2px solid #eee;
    // border-bottom: 2px solid #eee;
    //        position: fixed;
    width: 100%; //        top: 50px;
    //        display: none;
    box-shadow: 0px 1px 2px 0px rgba(68, 68, 68, 0.2);
    font-size: 14px;
    color: #999999;

    ul {
      line-height: 40px;
    }
  }

  .mobile_filter_container {
    display: block;
    height: 40px;
  }

  .filter_menu_fixed {
    position: fixed !important;
    width: 100%;
    top: 0;
  }

  .filter_menu_hide {
    display: none; //        transition: .3s all ease-in-out;
  }

  .mobile_nav_show {
    display: inline-block;
  }

  .mobile_nav_hide {
    display: none;
  }

  .mobile_nav_showout {
    display: inline-block;
  }

  .mobile_filter_menu {
    li {
      padding: 0;
      margin: 0;
      width: 12.9%;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .mobile_filter_menu_all {
    li {
      padding: 0;
      margin: 0;
      width: 24%;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

/* 联系创业者弹窗 */

.valid_prompt_mask {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1040;
}

.valid_prompt_container {
  display: none;
  z-index: 1041;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -260px;
  margin-top: -240px;
  border-radius: 12px;
  overflow: hidden;
  width: 520px;
  box-shadow: 3px 3px 9px 10px rgba(0, 0, 0, 0.21);
  font-size: 14px;

  .valid_prompt_head {
    background: #d8d8d8;
    color: #333333;
    width: 100%;
    padding: 10px 30px;

    #close_valid_prompt {
      width: 16px;
      height: 16px;
      margin-top: 7px;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    p {
      line-height: 30px;
      font-size: 16px;
      font-weight: 400;
    }

    .valid_prompt_avator {
      width: 72px;
      height: 72px;

      img {
        width: 100%;
        height: 100%;
        border: 3px solid #fff;
        border-radius: 50%;
      }
    }

    .valid_prompt_name {
      span {
        display: inline-block;
        height: 18px;
        width: 18px;
        font-size: 20px;
        line-height: 20px;

        img {
          width: 16px;
          display: block;
        }
      }
    }

    h2 {
      margin: 10px 4px;
      font-size: 20px;
      font-weight: 400;
      display: inline-block;
    }

    .valid_prompt_company {
      .pull-left {
        max-width: 200px;
      }
    }

    .valid_prompt_digest {
      padding-bottom: 15px;
    }
  }

  .valid_prompt_content {
    background: #f2f2f2;
    padding: 40px 50px;
    width: 100%;

    .valid_prompt_prompt {
      p {
        font-size: 14px;
        color: #999999;
        margin-top: 3px;
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      padding-bottom: 10px;
      margin: 0;
    }

    .valid_request_info {
      width: 100%;
      position: relative;

      textarea {
        //                width: calc(100% - 3px);
        //                width: -webkit-calc(100% - 3px);
        //                width: -moz-calc(100% - 3px);
        width: 100%;
        padding: 5px;
        padding-bottom: 20px; //margin-left: 3px;
        font-size: 14px;
        resize: none;
        border: 2px solid #cdcdcd;
        height: 190px; //      box-shadow: 0px 0px 0px 2px rgba(100,166,189,0.56);

        //      box-shadow: 0px 0px 0px 2px #c1c1c1;
        &:focus {
          outline: none;
        }
      }
    }

    .word_number_listener {
      position: absolute;
      right: 2px;
      bottom: 2px;
      font-size: 12px;
      color: #cbcbcb;

      .words_not_valid {
        color: red;
      }

      .words_was_valid {
        color: #cbcbcb;
      }
    }

    .valid_content_detail_con {
      padding-left: 10px;
      padding-right: 10px;

      .valid_content_detail {
        clear: both;
        color: #666666;

        &>div {
          float: left;
          line-height: 22px; // height: 18px;
        }

        .detail_item {
          margin-right: 15px;
        }

        .detail_inner_content {}

        .detail_select {
          border: 1px solid #979797;
          width: 16px;
          height: 16px;
          margin-top: 1px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }
        }

        .detail_fillin {
          a {
            color: #979797;
            font-size: 12px;
          }
        }
      }
    }

    .valid_btn_group_con {
      margin-top: 34px;

      &>div {
        float: left;
      }
    }

    .valid_btn_group {
      width: 100px;
      height: 35px;
      line-height: 35px;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
    }
  }
}

.valid_btn_cancel {
  border: 0;
  background: #e4e4e4;
  color: $lightGrey;
  margin-right: 20px;
}

.valid_btn_submit {
  background: $shitYellow;
  color: $lightBlack;
}

/* 认证弹窗 */

.valid_alert_box {
  display: none;
  z-index: 1042;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  border-radius: 12px;
  overflow: hidden;
  margin-top: -100px;
  margin-left: -260px;
  width: 520px;
  box-shadow: 3px 3px 9px 10px rgba(0, 0, 0, 0.21);
  font-size: 14px;

  .valid_alert_title {
    padding-left: 30px;
    padding-right: 30px;
    background: #d8d8d8; //        border-bottom: 1px solid #e4e4e4;

    h2 {
      color: #333333;
      font-size: 18px;
      font-weight: 400;
      margin: 0;
      padding: 17px 0;
    }

    .valid_alert_title_close {
      position: absolute;
      top: 0;
      right: 15px;
    }

    img {
      width: 16px;
      height: 16px;
      margin-top: 17px;
      cursor: pointer;
    }
  }

  .valid_alert_content {
    padding: 40px 50px;
    background: #fff;

    p {
      font-size: 16px;
      color: #333333;
      padding-bottom: 30px;
      line-height: 26px;
    }
  }

  .valid_btn_container {
    text-align: right;
  }

  .valid_alert_btn_group {
    width: 100px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    float: right;
  }

  #valid_alert_box_submit {

    //        margin: 0 auto;
    //        font-size: 16px;
    //        line-height: 40px;
    //        width: 160px;
    //        height: 40px;
    a {
      color: #fff;
    }
  }
}

/* phone go to cert */

@media (max-width: 550px) {
  .valid_alert_box {
    margin-top: -150px;
    margin-left: -150px;
    width: 300px;

    .valid_alert_title {
      margin-left: 12px;
      margin-right: 12px;
      background: #fff;
      border-bottom: 1px solid #e4e4e4;
      text-align: center;

      h2 {
        padding: 12px 0;
        font-size: 14px;
      }

      img {
        margin-top: 13px;
        height: 12px;
        width: 12px;
      }
    }

    .valid_alert_content {
      padding: 20px 15px;

      p {
        padding-bottom: 20px;
        font-size: 14px;
        color: #666666;
      }

      &>div {
        width: 100%;
      }
    }

    .valid_alert_btn_group {
      cursor: pointer;
      text-align: center;
      float: inherit;
    }

    #valid_alert_box_cancel {
      display: none;
    }

    #valid_alert_box_submit {
      margin: 0 auto;
      width: 130px;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
    }
  }
}

/* phone go to cert end */

/*****************/

/* new Login */

#download-qrcode {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 485px;
  height: 465px;
  background: #fff;
  z-index: 2002;
  text-align: center;

  .download-qrcode-wrap {
    .qrcode-title {
      margin: 50px auto;
      font-size: 18px;
      color: #27292e;
    }
  }

  .download-qrcode-handle-close {
    position: absolute;
    bottom: -70px;
    width: 485px;
    height: 50px;
    line-height: 50px;
    background-color: #dfdfe0;
    cursor: pointer;
  }
}

.login_form_con {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 485px;
  height: 465px;
  background: #fff; // box-shadow: 6px 5px 15px 16px rgba(0, 0, 0, 0.2);
  z-index: 2001;

  .login_form_top {
    padding-top: 50px;

    .login_form_back {
      cursor: pointer;
      padding-left: 25px;
      font-size: 16px;
      color: #999999;
      margin-bottom: 20px;

      &>div {
        display: inline-block;
      }

      img {
        width: 18px;
      }
    }

    .login_form_left {
      width: 485px;
      padding: 0 50px;

      .login_form_input {
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        position: relative;

        input {
          width: 100%;
          height: 50px;
          border: 1px solid #d8d8d8;
          font-size: 16px;
          padding-left: 15px;
          border-radius: 0;

          &:focus {
            outline: none;
          }
        }
      }

      .login_form_phonenum {
        input {
          padding-left: 103px;
        }
      }
    }

    .login_form_right {
      padding: 0 50px;
      width: 304px;

      .login_form_wechat {
        .login_form_qrcode {
          width: 200px;
          height: 200px;
          text-align: center;

          p {
            font-size: 14px;
            color: #666666;
          }

          img {
            width: 100%;
            height: 100%;
          }

          &>div {
            margin-top: 20px;
            font-size: 16px;
            color: #666666;
          }
        }
      }
    }

    .login_form_btn {
      height: 50px;
      font-size: 18px;
      color: #fff;
      background: #323232;
      text-align: center;
      line-height: 50px;
      font-weight: 400;
      letter-spacing: 10px;
      cursor: pointer;
    }
  }

  .login_form_areacode {
    position: absolute;
    top: 0;
    left: 0;
    width: 88px;
    height: 50px;
    font-size: 16px;
    color: #333333;
    line-height: 50px;
    text-align: center;
    background: #d8d8d8;
  }

  .login_form_verify {
    position: absolute;
    height: 36px;
    width: 100px;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    background: #323232;
    text-align: center; //   line-height: 36px;
    top: 7px;
    right: 7px; //   cursor: pointer;
    border: 0;
  }

  .login_form_forget {
    cursor: pointer; //       font-size: 16px;
    //        color: #3b6fe1;
  }

  .login_form_forget_con {
    &>div {
      margin-top: 12px;
    }
  }

  .login_form_checkbox {
    font-size: 14px;
    color: #9b9b9b;

    label {
      margin: 0;
      font-weight: 400;
      cursor: pointer;
    }

    a {
      color: #666666;
    }

    &>div {
      padding-right: 2px;
      margin-bottom: 20px;
      float: left;
    }
  }

  .login_form_title {
    h3 {
      margin-bottom: 30px;
      font-size: 18px;
      color: #27292e;
      text-align: center;
    }
  }

  .login_form_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    line-height: 60px;
    font-size: 16px;
    width: 100%;
    padding: 0 50px;
    height: 60px;
    border-top: 1px solid #d8d8d8;

    span {
      color: #3b6fe1;
      cursor: pointer;
    }
  }

  // 微信登陆
  .login_form_left_bottom {
    .login_form_third_title {
      display: inline-block;
      line-height: 16px;
      font-size: 14px;
      color: #6c7179;
    }

    .login_form_third_pic {
      display: inline-block;
      width: 28px;

      img {
        width: 100%;
        cursor: pointer;
      }
    }
  }

  .login-policy {
    margin-top: 15px;
    line-height: 13px;
    font-size: 13px;
    color: #9da0a5;
    text-align: center;

    a {
      color: #679cf8;
    }
  }

  .download-qrcode-handle-open {
    position: absolute;
    text-align: center;
    bottom: -70px;
    width: 485px;
    height: 50px;
    line-height: 50px;
    background-color: #dfdfe0;
    cursor: pointer;
  }
}

.login_form_close_img {
  cursor: pointer;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 18px;
  right: 18px;

  img {
    width: 100%;
    display: block;
  }
}

.login_form_blank {
  width: 100%;
  height: 30px;
}

.login_form_partline {
  color: #666666;
  padding-left: 10px;
  padding-right: 10px;
}

#btn_sign_up,
#btn_sign_in {
  cursor: pointer;
}

.login_bg_mask {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

#search_result_prompt {
  display: none;
}

/* new Login end */

/*************/

/* logout tips modal start*/

.logout-modal {
  top: 50%;
  transform: translateY(-50%);

  .modal-dialog {
    width: 454px;

    .modal-content {
      .modal-header {
        text-align: center;
        border: 0px;

        .img {
          display: inline-block;
          width: 40px;
          height: 40px;
          background: url("../../imgs/entrustment/entrustmentIcon.png") no-repeat;
          background-size: contain;
        }
      }

      .modal-body {
        text-align: center;
        padding: 0px;
        border: 0px;

        p {
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #27292e;
          letter-spacing: 0;
          line-height: 28px;
          text-align: center;
          padding: 0px 60px;
        }
      }

      .modal-footer {
        border: 0px;

        .my-btn {
          width: 100%;
          padding-top: 30px;
          text-align: center;

          span {
            text-align: center;
          }

          .btn-lf {
            display: inline-block;
            border: 1px solid #979797;
            border-radius: 100px;
            width: 138px;
            height: 40px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #6c7179;
            letter-spacing: 0;
            line-height: 40px;
            margin-right: 10px;
            cursor: pointer;
          }

          .btn-rt {
            display: inline-block;
            background: #FCDB00;
            border-radius: 100px;
            width: 140px;
            height: 40px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #27292E;
            letter-spacing: 0;
            line-height: 40px;
            cursor: pointer;

            a {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }

        .comfirm-btn {
          padding-top: 18px;
        }
      }
    }
  }
}

/* logout tips modal end*/

// new header end
@media (max-width: 767px) {
  .navbar-fixed-top {
    position: inherit;
  }

  .mobile_topblank_s {
    margin-top: 40px;
  }
}

@media (max-width: 500px) {
  .valid_prompt_container {
    width: 300px;
    margin-left: -150px;
    margin-top: -200px;

    .valid_prompt_content {
      padding: 15px;
    }
  }
}

.down-app {
  display: none;
  height: 60px;
  background-color: #f5f5f5;
  padding: 11px 15px 14px 15px;

  .app-icon {
    display: inline-block;
    background-image: url(/imgs/app-icon.png);
    height: 35px;
    width: 35px;
    background-size: cover;
    float: left;
  }

  .title {
    float: left;
    padding-left: 10px;
    text-align: left;

    .name {
      font-size: 15px;
      color: #4a4a4a;
    }

    .name-en {
      font-size: 12px;
      color: #979797;
    }
  }

  .down-btn {
    float: right;
    width: 80px;
    height: 30px;
    line-height: 30px;
    background-color: #ffd900;
    border-radius: 4px;
    margin-top: 4px;
    color: #333;
  }

  &.active {
    display: block;
  }
}